/** @format */

import { Container } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Faq from "react-faq-component";

const data = {
  title: "Frequently Asked Questions",
  rows: [
    {
      questionNumber: 1,
      title: "How can I earn auto-compounding staking rewards?",
      content: `You can earn lifetime auto-compounded rewards simply by providing liquidity in the pools.`,
    },
    {
      questionNumber: 2,
      title: "When do I become eligible for the life-time rewards?",
      content:
        "After launch holders will be eligible for the the rewards from staking and farming        ",
    },
    {
      questionNumber: 3,
      title: `What is the rewards for participating in the yield farm?`,
      content: `The rewards for any pain in the yield farm is in $OSCAR+WETH+USDC+FEE`,
    },
    {
      questionNumber: 4,
      title: "How does the price of $OSCAR token stablized?",
      content: `The stabilizing technique for the $OSCAR token is the deflationary tokenomics. After every few week a portion of the token is burned to regulate the Supply side.`,
    },
    {
      questionNumber: 5,
      title: "How is the demand of the $OSCAR token maintained?",
      content: `$OSCAR is the native token for the oscarswap. It is required to participate in the staking pool, to enjoy lifetime rewards. Hence, the demand of the token is solely derived from the inherent value it provides`,
    },
    {
      questionNumber: 6,
      title: "How Farming reward work?",
      content: `85% tokens mint for next 5 year daily OSCAR emission rate is 1945/Day. We do give reward in ETH and USDT as well that reward being generated from DEX fee which is 0.25%
      `,
    },
    {
      questionNumber: 7,
      title: "How you can sustain token price when giving 85% of total supply as reward ?",
      content: `As initially only 4.5% supply in circulation of total supply and remaining supply is locked for longer period or vested.
      <br/>  Though due to limited supply we gonna have high buying pressure for next couple of months at least. As we are distributing 100% dex fee to the farming pools for more user acqusition but later on when our platform is stable we will use the same fee for burning purpose and buy back.
      <br/>   This way we can become deflationary token and disrupt other DEX.`,
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleMarginBottom: "35px",
  rowTitleColor: "blue",
  // rowContentColor: 'grey',
  arrowColor: "red",
  rowTitleTextSize: "24px",
  rowContentPaddingTop: "20px",
  rowContentPaddingBottom: "20px",
  rowContentPaddingLeft: "100px",
  rowContentPaddingRight: "30px",
  transitionDuration: "1s",
  timingFunc: "ease",
};

const config = {
  // animate: true,
  // tabFocus: true,
  expandIcon: "+",
  collapseIcon: "x",
};

const formattedData = {
  ...data,
  rows: data.rows.map((row, index) => ({
    ...row,
    title: (
      <div className='d-flex'>
        <div className='number-dev'>
          {(index + 1).toString().padStart(2, "0")}
        </div>
        <div>{row.title}</div>
      </div>
    ),
    content: (
      <div className='d-flex'>
        <div className='mr-3'></div>
        <div>{row.content}</div>
      </div>
    ),
  })),
};

const Faqs = () => {
  const [rows, setRowsOption] = useState(null);

  // useEffect(() => {
  //   if (rows) {
  //     setTimeout(() => {
  //       rows[0].expand();
  //     }, 2500);

  //     setTimeout(() => {
  //       rows[0].close();
  //     }, 500000);

  //     setTimeout(() => {
  //       rows[0].scrollIntoView();
  //       // rows[0].scrollIntoView(true);
  //     }, 10000);
  //   }
  // }, [rows]);

  return (
    <div className='Faqs-section py-2 py-md-5'>
      <Container>
        <div className='faqs-border pb-0 pb-md-5'>
          <Faq
            data={formattedData}
            styles={styles}
            config={config}
            getRowOptions={setRowsOption}
          />
        </div>
      </Container>
    </div>
  );
};

export default Faqs;
