/** @format */

import React, { useState } from "react";

export default function CountDown(props) {
  const [days, setdays] = useState(null);
  const [hours, sethours] = useState(null);
  const [minutes, setminutes] = useState(null);
  const [seconds, setseconds] = useState(null);

  var countDownDate = new Date("Jun 21, 2023 13:00:00 UTC");
  var GetIme = new Date(countDownDate).getTime();

  setInterval(() => {
    var now = new Date().getTime();
    var distance = GetIme - now;
    var day = Math.floor(distance / (1000 * 60 * 60 * 24))
      .toString()
      .padStart(2, "0");
    var hour = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      .toString()
      .padStart(2, "0");
    var minute = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      .toString()
      .padStart(2, "0");
    var second = Math.floor((distance % (1000 * 60)) / 1000)
      .toString()
      .padStart(2, "0");

    setdays(day);
    sethours(hour);
    setminutes(minute);
    setseconds(second);
  }, 1000);

  return (
    <React.Fragment>
      {seconds > -1 && (
        <div className='timer-section mb-3' style={{ textAlign: "center" }}>
          <h2>Sale Ends in</h2>
          <div className='timer pt-2'>
            <span>{days}</span>
            <span>{hours}</span>
            <span>{minutes}</span>
            <span>{seconds}</span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
}
