/** @format */

import React from "react";
import { FaApple, FaGoogle } from "react-icons/fa";
import { BiChevronRight } from "react-icons/bi";

const Opertunity = () => {
  return (
    <div className='Opertunity-section mobile-cta py-5'>
      <div className='container'>
        <div className='position-relative'>
          <div className='mobile-cta-title title text-center section-title'>
            <h1
              className='title font-title anim_reveal text-white'
              style={{
                opacity: "1",
                visibility: "inherit",
                transform: "translate(0px, 0px)",
                translate: "none",
                rotate: "none",
                scale: "none",
              }}>
              Trade Anytime, Anywhere with 
              <span className='gradient'> Oscarswap </span>{" "}
            </h1>
          </div>

          <div className='mobile-img text-center'>
            <div className='mobile-center'>
              <img
                className='mobile-center-img h-auto ls-is-cached lazyloaded'
                width='865'
                height='536.8'
                src='assets/mob2.png'
                style={{ transform: "translate3d(0px, 28.2009px, 0px)" }}
                alt=''
              />
              <div className='mobile-cta-bg'>
                <img
                  className='lazyloaded'
                  src='assets/mobile-cta-bg.png'
                  alt=''
                />
              </div>
            </div>

            <div className='mobile-store-logo mobile-store-android'>
              <div
                className='mobile-android-light mobile-store-lights'
                style={{ opacity: "0.6443" }}>
                <img
                  className='maw-100lazyloaded'
                  src='assets/mobile-android-light.png'
                  alt=''
                />
              </div>
              <div className='mobile-android-base'>
                <img
                  className='h-auto lazyloaded'
                  src='assets/mobile-base.png'
                  alt=''
                />
              </div>
            </div>
            <div className='mobile-store-logo mobile-store-apple'>
              <div
                className='mobile-apple-light apple-store-lights'
                style={{ opacity: "0.6443" }}>
                <img
                  className='h-auto lazyloaded'
                  src='assets/mobile-apple-light.png'
                  alt=''
                />
              </div>
              <div className='mobile-apple-base'>
                <img
                  className='h-auto lazyloaded'
                  src='assets/mobile-base.png'
                  alt=''
                />
              </div>
            </div>

            <div
              className='mobile-block d-none d-md-block'
              style={{
                translate: "none",
                rotate: " none",
                scale: "none",
                transform: "translate(-180px, 90px)",
              }}>
              <img
                className='h-auto lazyloaded'
                src='assets/mobile-block.png'
                alt=''
              />
            </div>
            <div className='mobile-left'>
              <img
                className='h-auto lazyloaded'
                width='750'
                height='540.13'
                src='assets/mob3.png'
                alt=''
              />
            </div>
            <div className='mobile-right'>
              <img
                className='h-auto lazyloaded'
                width='750'
                height='468'
                src='assets/mob1.png'
                alt=''
              />
            </div>
          </div>
          <div className='mobile-qr text-center'>
            <div className='mobile-qr-icon rounded-8 mx-auto mb-7'>
              <img className='lazyloaded' src='assets/playstoreQR.png' alt='' />
            </div>
            <h4 className='title mb-4 text-white mt-2'>Download With</h4>
            <div className='d-inline-flex flex-column gap-3 flex-sm-row flex-wrap justify-content-center gap-7 mx-auto'>
              {/* <a
                href="/"
                target="_blank"
                className="fs-5 p-2 store-button btn btn-sm btn-social d-flex gap-2 justify-content-center align-items-center fw-semibold bg-white"
              >
                <FaApple className="text-black fs-3" />
                <span className="text-black">App Store</span>

                <BiChevronRight className="text-black" />
              </a> */}
              <a
                href='https://play.google.com/store/apps/details?id=com.oscarswap'
                target='_blank'
                className='fs-5 p-2 btn-lightstore-button btn btn-sm btn-social btn-icon d-flex gap-2 align-items-center fw-semibold bg-white'>
                <FaGoogle className='text-black' />
                <span className='text-black'>Google Play</span>

                <BiChevronRight className='text-black mt-1' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opertunity;
