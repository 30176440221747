/** @format */

import React from "react";

function Team() {
  const teamDetails = [
    {
      imgUrl: "assets/team/manuel.png",
      name: "Manuel Vazquez",
      title: "CEO",
      designation: "Ex Facebook Tech Lead",
    },
    {
      imgUrl: "assets/team/Stones.jpg",
      name: "Stones",
      title: "Marketing Director",
      designation: "Ex Facebook Tech Lead",
    },
    {
      imgUrl: "assets/team/Sharon.jpg",
      name: "Sharon",
      title: "CTO",
      designation: "Ex Facebook Tech Lead",
    },
    {
      imgUrl: "assets/team/SwapMaster.jpg",
      name: "Swap Master",
      title: "CMO",
      designation: "Ex Facebook Tech Lead",
    },
    {
      imgUrl: "assets/team/Exchange.jpg",
      name: "Exchange Eagle",
      title: "Project Manager",
      designation: "Ex Facebook Tech Lead",
    },
    {
      imgUrl: "assets/team/Brian.jpg",
      name: "Brian",
      title: "Head of Content Creator",
      designation: "Ex Facebook Tech Lead",
    },
  ];
  return (
    <div className='container-fluid section-padding'>
      <div className='container'>
        <div className='row'>
          <div className='col-md-12 blockmover-row2'>
            <h2>Our team</h2>

            <p>
              Our team is the magic behind the scenes, and the most important{" "}
              <br className='line-break' />
              block of the Oscarswap community.
            </p>
          </div>
        </div>

        <div className='row '>
          {teamDetails.map((v, i) => {
            return (
              <div className='col-md-4' key={i}>
                <div className='card team-cards'>
                  <div className='card-body'>
                    <div
                      className='team-img m-auto'
                      style={{ backgroundImage: `url(${v.imgUrl})` }}></div>
                    {/* <img src={v.imgUrl} alt='' className="w-50" /> */}
                    <h1>{v.name}</h1>
                    <p>{v.title}</p>
                    {/* <p>{v.designation}</p> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Team;
