/** @format */

import React from "react";
import Hero from "../components/elements/Hero";
import CoreFeature from "../components/elements/CoreFeature";
import AppSection from "../components/elements/AppSection";
import LearMore from "../components/elements/LearMore";
import TrustedPartners from "../components/elements/TrustedPartners";
import Faqs from "../components/utils/Faqs";
import Team from "../components/elements/Team";
import Ecosystem from "../components/elements/Ecosystem";
import EarnOscar from "../components/elements/EarnOscar";
import Opertunity from "../components/elements/Opertunity";
import Press from "../components/elements/Press";
import { useState } from "react";
import MusicPlayerSlider from "../components/utils/MusicPlayerSlider";
import { FaMusic } from "react-icons/fa";
const Home = () => {
  const [show, setShow] = useState(false);
  function openForm() {
    setShow(true);
  }

  function closeForm() {
    setShow(false);
  }
  return (
    <>
      <Hero />
      {/* <Press /> */}
      <CoreFeature />
      <Ecosystem />
      <EarnOscar />
      {/* <AppSection /> */}
      <Opertunity />
      <LearMore />
      <TrustedPartners />
      <Team />
      <Faqs />
    
        {show ? (
          <div className='chat-popup' >
            <MusicPlayerSlider />
          </div>
        ) : (
          <button className='open-button' onClick={openForm}>
            <FaMusic />
          </button>
        )}
     
    </>
  );
};

export default Home;
