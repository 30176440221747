/** @format */

import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const TrustedPartners = () => {
  const partner = [
    { imgUrl: "assets/trust-1.png", size: "60%" },
    { imgUrl: "assets/partner/bsc.png", size: "70%" },
    { imgUrl: "assets/partner/ethereum.svg", size: "40%" },
    { imgUrl: "assets/partner/2560px-Uniswap_Logo_and_Wordmark.png", size: "75%" },
    { imgUrl: "assets/Sushiswap.png", size: "70%" },
    { imgUrl: "assets/trust-2.png", size: "70%" },
    { imgUrl: "https://www.rugfreecoins.com/static/media/rfc_new_logo.8831dded85f4b6dec15af330721b6c72.svg", size: "75%" },
    { imgUrl: "https://expelee.com/static/media/ExpeleeLogoLight.2957dbdfb725edf6e580.png", size: "70%" },
    { imgUrl: "assets/XPLUS_Logo_Horizontal_White.svg", size: "25%" },
    { imgUrl: "assets/partner/lbank.svg", size: "50%" },
    { imgUrl: "assets/partner/ceras.png", size: "25%" },
    { imgUrl: "assets/partner/multichain.png", size: "35%" },
    { imgUrl: "assets/partner/relay.png", size: "20%" },
    { imgUrl: "assets/partner/maifinance.png", size: "30%" },
    { imgUrl: "assets/partner/autofarm.svg", size: "60%" },
    { imgUrl: "assets/partner/manta.png", size: "50%" },
    { imgUrl: "assets/partner/ud_wallet.png", size: "30%" },
    { imgUrl: "assets/partner/pickle.png", size: "30%" },
    { imgUrl: "assets/partner/transak.png", size: "60%" },
    { imgUrl: "assets/partner/axelar.png", size: "50%" },
    { imgUrl: "assets/partner/celer.png", size: "25%" },
    { imgUrl: "assets/partner/bcmc.png", size: "30%" },
    { imgUrl: "assets/partner/beefy.png", size: "35%" },
    { imgUrl: "assets/partner/nomad.svg", size: "55%" },
  ];
  return (
    <div className='TrustedPartners-section py-2 py-md-5'>
      <Container className='py-5'>
        <h2 className='mb-5'>Trusted Partners</h2>
        <Row>
          <Col xs={6} md={4} className='mt-3'>
            <a
              href='https://www.pinksale.finance/launchpad/0x6D0Ec6ca4c8f9979afBe1c1722f37fbB5C7547e4?chain=BSC'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='img-section position-relative  d-flex justify-content-center align-items-center'>
                <img
                  src='assets/trust-7.png'
                  className='img-fluid w-50'
                  alt=''
                />{" "}
                <span className='position-absolute top-0 start-100 translate-middle badge  bg-light text-dark'>
                  Fair launch
                  <span className='visually-hidden'>badge</span>
                </span>
              </div>{" "}
            </a>
          </Col>
          <Col xs={6} md={4} className='mt-3'>
            <a
              href='https://www.dexview.com/eth/0x1365532337ee5b6360dD4ccAA74f6A7A243f79e7'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='img-section position-relative  d-flex justify-content-center align-items-center'>
                <img
                  src='assets/dexview.svg'
                  className='img-fluid w-50'
                  alt=''
                />{" "}
                <span className='position-absolute top-0 start-100 translate-middle badge  bg-light text-dark'>
                  Chart
                  <span className='visually-hidden'>badge</span>
                </span>
              </div>{" "}
            </a>
          </Col>
          {/* <Col xs={6} md={3} className='mt-3'>
            <a
              href='https://app.uniswap.org/#/swap?outputCurrency=0x1365532337ee5b6360dD4ccAA74f6A7A243f79e7'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='img-section  d-flex justify-content-center align-items-center'>
                <img
                  src='assets/partner/2560px-Uniswap_Logo_and_Wordmark.png'
                  className='img-fluid w-50'
                  alt=''
                />
                <span className='position-absolute top-0 start-100 translate-middle badge  bg-light text-dark'>
                  Listing ETH
                  <span className='visually-hidden'>badge</span>
                </span>
              </div>
            </a>
          </Col> */}
          <Col xs={6} md={4} className='mt-3'>
            <a
              href='https://pancakeswap.finance/swap?outputCurrency=0xD6bf5A972e0C18d38F3a5e132880E39E6871f8A2'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='img-section  d-flex justify-content-center align-items-center'>
                <img
                  src='assets/PancakeSwap-Crypto-Logo-PNG-File.png'
                  className='img-fluid w-50'
                  alt=''
                />
                <span className='position-absolute top-0 start-100 translate-middle badge  bg-light text-dark'>
                  Listing BSC
                  <span className='visually-hidden'>badge</span>
                </span>
              </div>
            </a>
          </Col>
     
    
        </Row>


        <Row className='mt-3'>
    
          <Col xs={6} md={3} className='mt-3'>
            <a
              href='https://coinsult.net/safu_report/oscarswap-com/'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='img-section position-relative  d-flex justify-content-center align-items-center'>
                <img
                  src='https://coinsult.net/wp-content/uploads/2022/04/coinsult_full.svg'
                  className='img-fluid w-50'
                  alt=''
                />{" "}
                <span className='position-absolute top-0 start-100 translate-middle badge  bg-light text-dark'>
                  Safu
                  <span className='visually-hidden'>badge</span>
                </span>
              </div>{" "}
            </a>
          </Col>
          <Col xs={6} md={3} className='mt-3'>
            <a
              href='https://pinksale.notion.site/OSCAR-SWAP-KYC-Verification-eeb2a43f847a4df09416c577a08ecfbf'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='img-section position-relative  d-flex justify-content-center align-items-center'>
                <img
                  src='assets/trust-7.png'
                  className='img-fluid w-50'
                  alt=''
                />{" "}
                <span className='position-absolute top-0 start-100 translate-middle badge  bg-light text-dark'>
                  KYC
                  <span className='visually-hidden'>badge</span>
                </span>
              </div>{" "}
            </a>
          </Col>
          <Col xs={6} md={3} className='mt-3'>
            <a
              href='https://github.com/AnalytixAudit/Solidity/blob/main/20230605_AnalytixAudit_Oscarswap_Oscar_Audit.pdf'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='img-section  d-flex justify-content-center align-items-center'>
                <img
                  src='https://i0.wp.com/www.analytixaudit.com/wp-content/uploads/2022/05/Logo1233.png?fit=2000%2C647&ssl=1'
                  className='img-fluid w-50'
                  alt=''
                />
                <span className='position-absolute top-0 start-100 translate-middle badge  bg-light text-dark'>
                  Audit
                  <span className='visually-hidden'>badge</span>
                </span>
              </div>
            </a>
          </Col>
    
          <Col xs={6} md={3} className='mt-3'>
            <a
              href='https://skynet.certik.com/projects/oscarswap'
              target='_blank'
              rel='noopener noreferrer'>
              <div className='img-section  d-flex justify-content-center align-items-center'>
                <img
                  src='assets/trust-5.png'
                  className='img-fluid w-50'
                  alt=''
                />
                <span className='position-absolute top-0 start-100 translate-middle badge  bg-light text-dark'>
                  Audit
                  <span className='visually-hidden'>badge</span>
                </span>
              </div>
            </a>
          </Col>
        </Row>
      

        <Row className='mt-3 display-bg'>
          {partner.map((v, i) => {
            return (
              <Col xs={6} md={2} className='mt-3' key={i}>
                <div className='img-section  d-flex justify-content-center align-items-center'>
                  <img
                    src={v.imgUrl}
                    className='img-fluid '
                    style={{ width: v.size }}
                    alt=''
                  />
                </div>
              </Col>
            );
          })}
          {/* <Col xs={6} md={3} className='mt-3'>
            <div className='img-section  d-flex justify-content-center align-items-center'>
              <img
                src='assets/solidity-logo-vector.svg'
                className='img-fluid w-50'
                alt=''
              />
            </div>
          </Col>
          <Col xs={6} md={3} className='mt-3'>
            <div className='img-section  d-flex justify-content-center align-items-center'>
              <img src='assets/trust-3.svg' className='img-fluid w-50' alt='' />
            </div>
          </Col>
          <Col xs={6} md={3} className='mt-3'>
            <div className='img-section  d-flex justify-content-center align-items-center'>
              <img src='assets/trust-4.png' className='img-fluid w-75' alt='' />
            </div>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default TrustedPartners;
