/** @format */

import Container from "react-bootstrap/Container";
import { useState } from "react";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const cardData = [
  {
    id: "1",
    image: "assets/Frame-1.svg",
    title: "Swap",
    paragraph: "Compelling asset prices, and seamless cross-chain trading.",
    style: { background: "rgba(57, 217, 138, 0.05)", borderRadius: "10px" },
    button: "Trade Now",
    url: "https://app.oscarswap.com",
  },
  {
    id: "2",
    image: "assets/Frame-2.svg",
    title: "Yield Farms",
    paragraph: "Earn diversified yields in asset pair pools.",
    style: { background: "rgba(255, 204, 0, 0.05)", borderRadius: "10px" },
    button: "Start Earning",
    url: "https://app.oscarswap.com",
  },
  {
    id: "3",
    image: "assets/Frame-3.svg",
    title: "Liquidity",
    paragraph:
      "Earn auto-compounded returns on providing liquidity in the pools.",
    style: { background: "rgba(230, 53, 53, 0.05)", borderRadius: "10px" },
    button: "Launch App",
    url: "https://app.oscarswap.com",
  },
  {
    id: "4",
    image: "assets/Frame-4.svg",
    title: "Bridge",
    paragraph: "Now trade your tokens on all blockchains without any hassle.",
    style: { background: "rgba(51, 119, 255, 0.05)", borderRadius: "10px" },
    button: "Enter App",
    url: "https://app.oscarswap.com",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 2, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const CoreFeature = () => {
  const [active, setActive] = useState(null);

  return (
    <div className='CoreFeature-section py-5'>
      <Container>
        <h2>Core Features</h2>
        <p>Access all DeFi offerings on Arbitrum & Binance Smart chain.</p>
        <Carousel
          arrows={false}
          responsive={responsive}
          showDots={false}
          containerClass='carousel-container'>
          {cardData.map(item => (
            <div
              className={`card-bg text-center d-flex flex-column justify-content-between m-2 ${
                active === item && "border-red"
              }`}
              key={item.id}>
              <div className='content-sec'>
                <img
                  src={item.image}
                  className='img-fluid'
                  style={item.style}
                  alt=''
                />
                <div className='content'>
                  <div className='title'>{item.title}</div>
                  <p className='mb-3'>{item.paragraph}</p>
                </div>
              </div>
              <div className='btn-div'>
                <a href={item.url} target='_blank' rel='noopener noreferrer'>
                  {" "}
                  <button
                    onClick={() => setActive(item)}
                    className={`btn btn-conn ${
                      active === item && "launch-app"
                    }`}
                    type='submit'>
                    {item.button}
                  </button>
                </a>
              </div>
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default CoreFeature;
