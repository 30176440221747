import Container from "react-bootstrap/Container";
import { useState } from "react";
import { BsArrowRightCircle } from "react-icons/bs";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const cardData = [
  {
    id: "1",
    image: "assets/learmore-Icon-2.png",
    title: "Staking",
    paragraph:
      "Stake in limitless simple and auto-compounding staking pools and earn profits without any upper bounds.",
    style: { background: "rgba(6, 194, 112, 0.05)", borderRadius: "20px" },
    button: "Trade Now",
  },

  {
    id: "3",
    image: "assets/learmore-Icon-1.png",
    title: "Solid Token-economics workflow",
    paragraph:
      "HODL $OSCAR token, because of its stable value and solid token distributions to enjoy tension-free crypto trading.",
    style: { background: "rgba(6, 194, 112, 0.05)", borderRadius: "20px" },
    button: "Trade Now",
  },
  {
    id: "2",
    image: "assets/learmore-Icon-2.png",
    title: "Diversified Yields",
    paragraph:
      "The diversified yield in the Oscarswap yield farm comprises up to three high value coins, one of which is a stablecoin. Collectively the three coins make an excellent bargain",
    style: { background: "rgba(255, 204, 0, 0.05)", borderRadius: "20px" },
    button: "Start Earning",
  },
  {

    id: "4",
    image: "assets/learmore-Icon-1.png",
    title: "Roadmap",
    paragraph:
      "For more information on progress, and to understand Oscar goals and vision, check out the roadmap.",
    style: { background: "rgba(255, 204, 0, 0.05)", borderRadius: "20px" },
    button: "Start Earning",
  },
  {

    id: "5",
    image: "assets/learmore-Icon-2.png",
    title: "Spot prices",
    paragraph:
      "The Oscarswap swapping feature offers the best spot prices for crypto pairs to eliminate any arbitrage.",
    style: { background: "rgba(255, 204, 0, 0.05)", borderRadius: "20px" },
    button: "Start Earning",
  },
  {

    id: "6",
    image: "assets/learmore-Icon-1.png",
    title: "No bound trading",
    paragraph:
      "Oscarswap is a highly interoperable DEX with L2 bridge technology that enables users to easily transfer their assets to and from other chains.",
    style: { background: "rgba(255, 204, 0, 0.05)", borderRadius: "20px" },
    button: "Start Earning",
  },
  {

    id: "7",
    image: "assets/learmore-Icon-2.png",
    title: "Arbitrum L2 scaling solution",
    paragraph:
      "The underlying Arbitrum L2 chain provides high scalability on the network.",
    style: { background: "rgba(255, 204, 0, 0.05)", borderRadius: "20px" },
    button: "Start Earning",
  },
];

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 2, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

const LearMore = () => {
  const [active, setActive] = useState(null);

  return (
    <div className="LearMore-section ">
      <Container>
        <h2>Learn more</h2>

        <Carousel
          arrows={false}
          responsive={responsive}
          showDots={true}
          containerClass="carousel-container"
        >
          {cardData.map((item) => (
            <div
              className={`card-bg d-flex align-items-center gap-4 m-2 ${
                active === item && "border-red" }`}
              key={item.id}
            >
              <img
                src={item.image}
                className="img-fluid d-none d-md-inline-block"
                style={item.style}
                alt=""
              />
              <div className="d-flex justify-content-between align-items-center d-block d-md-none w-100">
                <img
                  src={item.image}
                  className="img-fluid"
                  style={item.style}
                  alt=""
                />
                <BsArrowRightCircle
                  onClick={() => setActive(item)}
                  type="submit"
                  className={`arrow-icon ${
                    active === item && "arrow-icon-active"
                  }`}
                />
              </div>
              <div className="content">
                <div className="d-flex justify-content-between d-none d-md-flex">
                  <div className="title">{item.title}</div>
                  <BsArrowRightCircle
                    onClick={() => setActive(item)}
                    type="submit"
                    className={`arrow-icon ${
                      active === item && "arrow-icon-active"
                    }`}
                  />
                </div>
                <div className="title d-inline-block d-md-none">{item.title}</div>
                <p className="mb-0">{item.paragraph}</p>
              </div>
            </div>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default LearMore;
