/** @format */

import React from "react";
import Container from "react-bootstrap/Container";

const Ecosystem = () => {
  return (
    <div className='ecosystem-section py-5'>
      <Container>
        <img src='assets/oscar-logo.png' className="text-center m-auto d-block mb-3" width={100} alt='' />
        <h2>Creating a unified DEX for billions, securely</h2>
        <p>Oscar aims to build an integrated DeFi ecosystem on Arbitrum & Binance Smart chain</p>
        <div className='row mt-5 pt-5 text-center'>
          <div className='col-lg-4 d-flex align-items-center flex-column mb-5 '>
            <div className='feature icon-1'>
              <img src='assets/fair-launch.svg' />
              <h5>Fair Launch</h5>
              <p>
                The fairest way to launch Oscarswap and eFnsure full
                decentralization: no presale, no investor, and no pre-mine.
              </p>
            </div>
          </div>
          <div className='col-lg-4  d-flex align-items-center flex-column mb-5'>
            <div className='feature pt-4 icon-2'>
              <img src='assets/security-gov.svg' />
              <h5>Robust Security &amp; Governance</h5>
              <p>
                Security as the core pillar of protocol design and community
                governance at heart. Platform governance will be passed
                gradually to the community
              </p>
            </div>
          </div>
          <div className='col-lg-4  d-flex align-items-center flex-column mb-5'>
            <div className='feature pt-4 icon-3'>
              <img src='assets/best-price.svg' />
              <h5>Best Prices</h5>
              <p>
                Oscar's AMM algorithm predicated on a variation of the constant
                product formula ensures deep liquidity, resulting in the best
                prices and lowest slippage.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Ecosystem;
