/** @format */

import React from "react";
import Container from "react-bootstrap/Container";
import { IoIosArrowForward } from "react-icons/io";

const EarnOscar = () => {
  return (
    <div className='oscar-section py-5'>
      <Container>
        <div className='row vh-100 d-flex justify-content-start align-items-center'>
          <div className='col-lg-6'>
            <h2>Earn OSCAR returns with maximum utility</h2>
            <p>
              OSCAR token is the center of Oscarswap's ecosystem. Buy, trade,
              earn, stake and vote with OSCAR!
            </p>
            <div className='btn-section btn-launch-buy'>
              <a
                href='https://app.oscarswap.com'
                rel='noreferrer'
                target='_blank'
                className='btn-buy d-flex justify-content-between px-5'>
                <span>
                  <img src='assets/oscar.png' width={20} alt='' /> &nbsp; Buy
                  Oscar
                </span>{" "}
                <IoIosArrowForward className='fs-6' />
              </a>
              {/* <Link to='/' rel="noreferrer" target='_blank' >swap Now</Link> */}
              <a
                href='https://oscarswap-official.gitbook.iox`'
                rel='noreferrer' className="w-br"
                target='_blank'>
               Learn more
              </a>
            </div>
          </div>
          <div className='col-lg-6'></div>
        </div>
      </Container>
    </div>
  );
};

export default EarnOscar;
