/** @format */

import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Press from "../../components/elements/Press";

const Base = props => {
  return (
    <div>
      <Press />
      <Header />
      {props.children}
      <Footer />
    </div>
  );
};

export default Base;
