/** @format */

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import { BsPatchCheckFill } from "react-icons/bs";
import CountDown from "../utils/CountDown";
import Modal from "react-bootstrap/Modal";
import { useEffect } from "react";
import audioFile from "../../rap.mp3";
import ReactAudioPlayer from "react-audio-player";
import MusicPlayerSlider from "../utils/MusicPlayerSlider";

const Hero = props => {
  const [show, setShow] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const audio = new Audio(audioFile);

  // Define an event handler for when the audio is played
  const handlePlay = () => {
    audio.play();
    setIsPlaying(true);
  };

 
  const handleClose = () => {
    setShow(false);
  };
  // const handleShow = () => {
  //   setShow(true);
  // };
  // useEffect(() => {
  //   handleShow();
  // }, []);

  return (
    <>
      <div className='Hero-Section py-5'>
        <div className='container'>
          <div className='row text-center pt-0 '>
            {/* <CountDown /> */}
            <div className='btn-section mt-1 mx-0 pl-0 btn-launch-buy'>
            {/* <a
              href='https://www.pinksale.finance/launchpad/0xf5da1f4b3fea712d1c26864fdb791e168344db16?chain=ETH'
              rel='noreferrer'
              target='_blank'
              className='btn-sale d-flex justify-content-between annn  px-4'>
              <span>
                <img
                  src='https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png'
                  width={20}
                  alt=''
                />{" "}
                &nbsp; Join on Pinksale
              </span>{" "}
              <IoIosArrowForward className='fs-6' />
            </a> */}
        
                <a
              href='https://pancakeswap.finance/swap?outputCurrency=0xD6bf5A972e0C18d38F3a5e132880E39E6871f8A2'
              rel='noreferrer'
              target='_blank'
              className='btn-buy d-flex justify-content-between annn  px-4 '>
              <span>
                <img
                  src='assets/pancakeswap-logo.png'
                  width={20}
                  alt=''
                />{" "}
                &nbsp; Trade On PCS
              </span>{" "}
              <IoIosArrowForward className='fs-6' />
            </a>
            </div>
            <div className='content-section pt-md-2'>
              <h2>EXCHANGE</h2>
              <h3>DEX for everyone!</h3>
              <h6>Convenient. Effective. Safe </h6>
              <h5>Embrace the change!</h5>

              <div className='btn-section  btn-launch-buy'>
                   <a
                  href='https://coinsult.net/safu_report/oscarswap-com/'
                  rel='noreferrer'
                  target='_blank'
                  className=' btn-launch d-flex justify-content-between px-4'>
                  <BsPatchCheckFill className='fs-6' />
                  <img
                    src='https://coinsult.net/wp-content/uploads/2022/04/coinsult_full.svg'
                    style={{ width: 90, display: "inline-block" }}
                    alt=''
                  />{" "}
                  <strong>Safu</strong>{" "}
                </a>
                <a
                  href='https://pinksale.notion.site/OSCAR-SWAP-KYC-Verification-eeb2a43f847a4df09416c577a08ecfbf'
                  rel='noreferrer'
                  target='_blank'
                  className=' btn-launch d-flex justify-content-between px-4'>
                  <BsPatchCheckFill className='fs-6' />
                  <img
                    src='assets/trust-7.png'
                    style={{ width: 70, display: "inline-block" }}
                    alt=''
                  />{" "}
                  <strong>KYC</strong>{" "}
                </a>

                <a
                  href='https://github.com/AnalytixAudit/Solidity/blob/main/20230605_AnalytixAudit_Oscarswap_Oscar_Audit.pdf'
                  rel='noreferrer'
                  target='_blank'
                  className=' btn-launch d-flex justify-content-between px-4'>
                  <BsPatchCheckFill className='fs-6' />
                  <img
                    src='https://i0.wp.com/www.analytixaudit.com/wp-content/uploads/2022/05/Logo1233.png?fit=2000%2C647&ssl=1'
                    style={{ width: 90, display: "inline-block" }}
                    alt=''
                  />{" "}
                  <strong>Audit</strong>{" "}
                </a>

             
              </div>
              <div className='btn-section btn-launch-buy'>
                <a
                  href='https://app.oscarswap.com'
                  rel='noreferrer'
                  target='_blank'
                  className='btn-buy d-flex justify-content-between px-5'>
                  <span>
                    <img src='assets/oscar.png' width={20} alt='' /> &nbsp; Buy
                    Oscar
                  </span>{" "}
                  <IoIosArrowForward className='fs-6' />
                </a>
                {/* <Link to='/' rel="noreferrer" target='_blank' >swap Now</Link> */}
                <a
                  href='https://stake.oscarswap.com'
                  rel='noreferrer'
                  target='_blank'>
                  Staking
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        {...props}
        show={show}
        onHide={handleClose}
        aria-labelledby='contained-modal-title-vcenter'>
        <Modal.Body>
          <span className='close' onClick={handleClose}>
            &times;
          </span>
          <h3>Oscarswap Fair launch</h3>
          <CountDown />
          <div className='btn-section mt-3 mx-0 pl-0 btn-launch-buy'>
            {/* <a
              href='https://pancakeswap.finance/swap?outputCurrency=0xD6bf5A972e0C18d38F3a5e132880E39E6871f8A2'
              rel='noreferrer'
              target='_blank'
              className='btn-buy d-flex justify-content-between annn px-4 '>
              <span>
                <img
                  src='assets/pancakeswap-logo.png'
                  width={20}
                  alt=''
                />{" "}
                &nbsp; Trade On PCS
              </span>{" "}
              <IoIosArrowForward className='fs-6' />
            </a> */}
            <a
              href='https://www.pinksale.finance/launchpad/0xf5da1f4b3fea712d1c26864fdb791e168344db16?chain=ETH'
              rel='noreferrer'
              target='_blank'
              className='btn-sale annn d-flex justify-content-between  px-4'>
              <span>
                <img
                  src='https://www.pinksale.finance/static/media/pinkswap.a95de4f3.png'
                  width={20}
                  alt=''
                />{" "}
                &nbsp; Join  on  Fair Launch
              </span>{" "}
              <IoIosArrowForward className='fs-6' />
            </a>
          </div>
          <div className='row text-center mt-3'>
            <div className='col-6 text-light'>
              <h5>Softcap</h5>
            </div>
            <div className='col-6 text-warning fw-bold'>
              <h5>0.1 ETH</h5>
            </div>
          </div>
          <div className='text-center mt-3'>
            
            <MusicPlayerSlider />{" "}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Hero;
