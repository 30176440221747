/** @format */

import React from "react";
import { SiMedium } from "react-icons/si";
import {
  FaReddit,
  FaGithub,
  FaTwitter,
  FaTelegram,
  FaBook,
  FaInstagram,
  FaFacebook,
  FaDiscord,
} from "react-icons/fa";
import { IoIosArrowForward } from "react-icons/io";
const Footer = () => {
  return (
    <div className='footer-section container-fluid'>
      <div className='row'>
        <div className='col-lg-12 footer-btn m-auto text-center'>
          <a
            href='https://cryptojobslist.com/companies/oscarswap'
            rel='noreferrer'
            target='_blank'
            title='We are hiring!'
            >
            <img
              src='https://cryptojobslist.com/images/logo-circle-white.svg'
              target='_blank'width={20}
              alt='We are hiring!'
            /> &nbsp; We are hiring!  <IoIosArrowForward className='fs-6' />
          </a>
          {/* <a
            href='https://app.oscarswap.com'
            rel='noreferrer'
            target='_blank'
            className=' btn-launch d-flex justify-content-between m-auto px-5'>
            <span>Launch App</span> <IoIosArrowForward className='fs-6' />
          </a> */}
        </div>
      </div>
      <div className='row mt-4  pb-3'>
        <div className='col-md-4'>
          <div className='copyright text-white py-3 text-center'>
            <p className='mb-0'>Terms and Conditions | © 2023 | Oscarswap</p>
          </div>
        </div>
        <div className='col-md-8 text-end m-auto'>
            <a
              href='https://oscarswap-official.gitbook.io'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <FaBook className='mx-2' size={20} />
            </a>
            <a
              href='https://medium.com/@oscarswap'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <SiMedium className='mx-2' size={20} />
            </a>
            <a
              href='https://twitter.com/Oscar_Swap'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <FaTwitter className='mx-2' size={20} />
            </a>
            <a
              href='http://t.me/Oscar_Swap'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <FaTelegram className='mx-2' size={20} />
            </a>
            <a
              href='https://discord.gg/5GQJ8B6JSz'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <FaDiscord className='mx-2' size={20} />
            </a>
            <a
              href='https://www.reddit.com/r/oscar_swap'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <FaReddit className='mx-2' size={20} />
            </a>
            <a
              href='https://github.com/Oscarswap'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <FaGithub className='mx-2' size={20} />
            </a>
            <a
              href='https://www.instagram.com/oscar_swap/'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <FaInstagram className='mx-2' size={20} />
            </a>
            <a
              href='https://www.facebook.com/Oscarswap'
              target='_blank'
              rel='noopener noreferrer'>
              {" "}
              <FaFacebook className='mx-2' size={20} />
            </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
