/** @format */

import React from "react";

const Header = () => {
  return (
    // <header className='Header-Section'>
      <nav className='navbar navbar-expand-lg  Header-Section' >
        <div className='container-fluid'>
          <a className='navbar-brand d-flex align-items-center gap-2' href='/'>
            {" "}
            <img
              src='assets/oscarswap.png'
              width={180}
              className='img-fluid'
              alt=''
            />
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarSupportedContent'
            aria-controls='navbarSupportedContent'
            aria-expanded='false'
            aria-label='Toggle navigation'>
            <span className='navbar-toggler-icon' />
          </button>
          <div className='collapse navbar-collapse' id='navbarSupportedContent'>
            <div className='me-auto'></div>
            <ul className='navbar-nav mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a
                  className='nav-link active'
                  href='https://twitter.com/Oscar_Swap'
                  rel='noreferrer'
                  target='_blank'>
                  <img
                    src='assets/twitter.svg'
                    className='img-fluid'
                    width={25}
                    height={25}
                    alt=''
                  />
                  <span>Twitter</span>
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='https://discord.gg/5GQJ8B6JSz'
                  rel='noreferrer'
                  target='_blank'>
                  <img
                    src='assets/discord.svg'
                    className='img-fluid'
                    width={25}
                    height={25}
                    alt=''
                  />
                  <span>Discord</span>
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link'
                  href='http://t.me/Oscar_Swap'
                  rel='noreferrer'
                  target='_blank'>
                  <img
                    src='assets/telegram.svg'
                    className='img-fluid' 
                    width={25}
                    height={25}
                    alt=''
                  />
                  <span>Telegram</span>
                </a>
              </li>
            </ul>
            <div className='navbar-item'>
              <a href='https://oscarswap-official.gitbook.io' rel='noreferrer' target='_blank'>Documentation</a>
            </div>
            <div className='navbar-item'>
              <a href='https://bridge.oscarswap.com'  rel='noreferrer'
                target='_blank'>Bridge</a>
            </div>
            <div className='navbar-item'>
              <a href='https://stake.oscarswap.com'  rel='noreferrer'
                target='_blank'>Stake Oscar</a>
            </div>
            <div className='navbar-item'>
              <a href='https://app.oscarswap.com'  rel='noreferrer'
                target='_blank'>Launch App</a>
            </div>
       
          </div>
        </div>
      </nav>
    // </header>
  );
};

export default Header;
